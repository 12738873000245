import React from 'react'
import Layout from '../components/layout'
import { VideoClip } from '../components/backgroundVideo'
import styles from './styles.module.css'
import SEO from '../components/seo'

export default () => {
    return (
        <Layout>
            <SEO 
                title='tutorials' 
                description="🧩 Stuff I share from what I've learned."
                pathname='/tutorials/'
            />
            <VideoClip clip='Playground'/>
            <section className={styles.section}>
                <p className={styles.note} id='note'>
                    {"<"}Coming soon...{"/>"}
                </p> 
            </section>
        </Layout>
    )
}